<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="岗位名称">
              <a-input v-model="searchData.name" placeholder="岗位名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="所属部门">
              <a-tree-select
                v-model="searchData.deptId"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="depts"
                treeDefaultExpandAll
                @change="handleChangeDept"
                placeholder="部门"
                allowClear
              >
              </a-tree-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="isAdd" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="isEdit" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button v-if="isDel" type="primary" @click="toHandler('del')">删除</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="flagEnable" slot-scope="text, record">
          <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)" />
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerStation-edit-modal ref="DealerStationEditModal" @reload="getData"></DealerStation-edit-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerStationEditModal from './components/DealerStationEditModal.vue'
import { delDealerStation, listDealerStation, selectByIdDealerStation } from './api/DealerStationApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerStation',
  components: {
    DealerStationEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isAdd: checkPermission('dealer:station:add'),
      isEdit: checkPermission('dealer:station:edit'),
      isDel: checkPermission('dealer:station:del'),
      expandedKeys: [0],
      dataList: [],
      StationId: 0,
      depts:[]
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listDealerStation({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
       getDeptList() {
      this.axios.get('/api/base/system/department/listDealerAll').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
      // if (extra.selected && extra.triggerNode.$children.length > 0) {
      //   this.$message.error('不能选择父节点 ' + extra.triggerNode.title)
      //   value.splice(
      //     value.findIndex((item) => item === extra.triggerValue),
      //     1
      //   )
      // }
    },
    // 获取左侧树
    getTreeList() {
      this.axios.post('/api/dealer/dealer/dealerInfo/getTree').then((res) => {
        this.dataList = res.body
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中筛选
    handleSelect(value) {
      let id = Number(value.toString())
      this.StationId = id
      this.getData()
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerStationEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerStationEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerStation(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
    onChangeStatus(record) {
      let status = record.flagEnable
      this.axios
        .get(`/api/base/system/station/statusDealer/isEnable?stationId=${record.id}&flagEnable=${status}`)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getList()
          } else {
            this.getList()
          }
        })
        .catch((err) => {
          this.getList()
        })
    },
  },
  created() {
    this.getData()
    this.getTreeList()
    this.getDeptList()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.tree-outer {
  height: 500px;
  overflow-y: auto;
}
</style>
