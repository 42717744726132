/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-15 10:43:12
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 10:46:53
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerStation = params => axios({
    url: '/api/base/system/station/listDealer',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerStation = params => axios({
    url: '/api/base/system/station/addDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerStation = params => axios({
    url: '/api/base/system/station/editDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerStation = params => axios({
    url:'/api/base/system/station/delDealer/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerStation = params => axios({
    url: '/api/base/system/station/selectDealerById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
